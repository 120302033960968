@font-face {
  font-family: 'ezy';
  src: local('Euclid Square Regular'), local('Euclid-Square-Regular'),
    url('fonts/EuclidSquare-Regular.woff2') format('woff2'),
    url('fonts/EuclidSquare-Regular.woff') format('woff'),
    url('fonts/EuclidSquare-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'ezy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#map {
  width: 100%;
}
a {
  text-decoration: none;
  color: #a18669;
}
a:link {
  text-decoration: none;
  color: #a18669;
}
a:visited {
  text-decoration: none;
  color: #a18669;
}
a:active {
  text-decoration: none;
  color: #a18669;
}
a:hover {
  text-decoration: none;
  color: #a18669;
}
